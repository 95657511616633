import { Start } from "@mui/icons-material";
import { Chart as ChartJS, registerables } from "chart.js";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

const MainDashboard = ({ valuation, tab }) => {
  const value = [];
  valuation?.map((item) => value.push(item.value));

  const getMonthlyAmountData = (data) => {
    if (!data || data.length === 0) return Array(12).fill(0);
    const monthlyCounts = Array(12).fill(0);

    const getMonthDiff = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return (
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth())
      );
    };
    const addValueToMonth = (start, end, value) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const totalMonths = getMonthDiff(start, end) + 1;
      const valuePerMonth = value;

      for (let i = 0; i < totalMonths; i++) {
        const currentMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + i,
          1
        );
        const monthIndex = currentMonth.getMonth();
        monthlyCounts[monthIndex - 1] += valuePerMonth;
      }
    };
    data?.forEach((entry) => {
      const { startDate, endDate, value } = entry;
      addValueToMonth(startDate, endDate, value);
    });
    return monthlyCounts;
  };
  const getYearlyAmountData = (data) => {
    if (!data || data.length === 0) return [];

    const startYears = data.map((entry) =>
      new Date(entry.startDate).getFullYear()
    );
    const endYears = data.map((entry) => new Date(entry.endDate).getFullYear());
    const minYear = Math.min(...startYears);
    const maxYear = Math.max(...endYears);
    const yearRange = maxYear - minYear + 1;

    const yearlyCounts = Array(yearRange).fill(0);

    const getYearDiff = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return endDate.getFullYear() - startDate.getFullYear();
    };

    const addValueToYear = (start, end, value) => {
      const startDate = new Date(start);
      const totalYears = getYearDiff(start, end) + 1;
      const valuePerYear = value;
      for (let i = 0; i < totalYears; i++) {
        const currentYear = startDate.getFullYear() + i;
        const index = currentYear - minYear;
        yearlyCounts[index] += valuePerYear;
      }
    };

    data.forEach((entry) => {
      const { startDate, endDate, value } = entry;
      addValueToYear(startDate, endDate, value);
    });
    return yearlyCounts;
  };

  const generate1Labels = (data) => {
    const labels = [];
    data?.forEach((entry) => {
      const startDate = new Date(entry.startDate);
      const endDate = new Date(entry.endDate);
      const startMonth = startDate.toLocaleString("default", {
        month: "short",
      });
      const endMonth = endDate.toLocaleString("default", { month: "short" });
      const label = `${startMonth}-${endMonth}`;
      if (!labels.includes(label)) {
        labels.push(label);
      }
    });
    return labels;
  };
  const generate5Labels = (data) => {
    const labels = [];
    data?.forEach((item) => {
      const startDate = new Date(item.startDate);
      const endDate = new Date(item.endDate);
      const startYear = startDate.toLocaleString("default", {
        year: "numeric",
      });
      const endYear = endDate.toLocaleString("default", { year: "numeric" });
      const label = `${startYear}-${endYear}`;
      if (!labels.includes(label)) {
        labels.push(label);
      }
    });
    return labels;
  };
  const oneYearData = getMonthlyAmountData(valuation);
  const fiveYearData = getYearlyAmountData(valuation);
  const oneYearLabels = generate1Labels(valuation);
  const fiveYearLabels = generate5Labels(valuation);

  const linechart = {
    labels: tab === "oneYear" ? oneYearLabels : fiveYearLabels,
    datasets: [
      {
        label: "Valuation",
        data: tab === "oneYear" ? oneYearData : fiveYearData,
        borderWidth: 3,
        // backgroundColor: "transparent",
        // borderColor: "#742774",
        // pointBackgroundColor: "#ffffff",
        // pointRadius: 0,
        type: "bar",
        // backgroundColor: '#742774',
        // borderColor: '#742774',
        backgroundColor: "#023220",
        borderColor: "#023220",
        borderWidth: 1,
        tension: 0.3,
      },
    ],
  };
  const linechartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
      legend: {
        position: "top",
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          fontColor: "#c8ccdb",
        },
        barPercentage: 0.7,
        display: true,
        grid: {
          borderColor: "rgba(119, 119, 142, 0.2)",
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        grid: {
          borderColor: "rgba(119, 119, 142, 0.2)",
        },
        scaleLabel: {
          display: true,
          labelString: "Thousands",
          fontColor: "transparent",
        },
      },
    },
    interaction: {
      intersect: false,
    },
  };
  return (
    <Line
      options={linechartoptions}
      data={linechart}
      className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
      height="100"
    />
  );
};

export default MainDashboard;
