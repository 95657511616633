import serverCall from "../../serverCall";
const CreateProperty = async (data) => {
  const response = await serverCall.post(
    "/property/admin/create-propery",
    data
  );
  return response;
};
const GetAllProperties = async (page, limit, data) => {
  // try {
  const response = await serverCall.get(
    `/property/admin/get-all-property?pageNumber=${page}&pageSize=${limit}`,
    data
  );
  return response;
  // } catch (e) {
  //     console.log("e", e);
  // }
};
const GetOne = async (id) => {
  // try {
  const response = await serverCall.get(`/property/admin/get-property/${id}`);
  return response;
  // } catch (e) {
  //     console.log("e", e);
  // }
};
const LockProperty = async (id) => {
  const response = await serverCall.post(`/property/admin/lock-property/${id}`);
  return response;
};
const UpdateOne = async (id, data) => {
  // try {
  const response = await serverCall.patch(
    `/property/admin/update-property/${id}`,
    data
  );
  return response;
  // } catch (e) {
  //     console.log("e", e);
  // }
};
const Delete = async (id) => {
  // try {
  const response = await serverCall.delete(
    `/property/admin/delete-property/${id}`
  );
  return response;
  // } catch (e) {
  //     console.log("e", e);
  // }
};
const ImageDelete = async (propertyId, imageUrl) => {
  // try {
  const response = await serverCall.delete(
    `/property/admin/delete-property-image/{imageUrl}?propertyId=${propertyId}&imageUrl=${imageUrl}`
  );
  return response;
  // } catch (e) {
  //     console.log("e", e);
  // }
};
const getPropertyWiseShare = async (propertyId) => {
  const response = await serverCall.get(
    `/property/admin/get-propertywise-share?propertyId=${propertyId}`
  );
  return response;
};

const unlockShares = async (propertyId, numberOfUnlockShare) => {
  try {
    const response = await serverCall.post(
      `/property/admin/unlock-share?propertyId=${propertyId}&numberOfUnlockShare=${numberOfUnlockShare}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const shareDetails = async (shareId, page, limit) => {
  const response = await serverCall.get(
    `open-order/admin/get-share-detail?shareId=${shareId}&pageNumber=${page}&pageSize=${limit}`
  );
  return response;
};
const graph = async (type, propertyId) => {
  const response = await serverCall.get(
    `/property/admin/graph?type=${type}&propertyId=${propertyId}`
  );
  return response;
};
const archiveProperty = async (propertyId) => {
  const response = await serverCall.post(
    `/property/admin/archive-property/${propertyId}`
  );
  return response;
};
const unArchive = async (propertyId) => {
  const response = await serverCall.post(
    `property/admin/unArchive-property/${propertyId}`
  );
  return response;
};
const changeOwner = async (data) => {
  const response = await serverCall.post(
    `/open-order/admin/change-share-owner`,
    data
  );
  return response;
};
const PropertiesService = {
  CreateProperty,
  GetOne,
  LockProperty,
  GetAllProperties,
  Delete,
  UpdateOne,
  ImageDelete,
  getPropertyWiseShare,
  shareDetails,
  graph,
  archiveProperty,
  unArchive,
  changeOwner,
  unlockShares,
};
export default PropertiesService;
