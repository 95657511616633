import React, { useCallback, useEffect, useRef } from "react";
import withLoader from "../../../layout/loader/withLoader";
import DataTable from "react-data-table-component";
import { Button, Card } from "react-bootstrap";
import { useState } from "react";
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import UserService from "../../../services/user.service";
import UserDetails from "./UserDetailsModal";
import SelectAll from "react-select";
import { wrap } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import FreezeModal from "./FreezeModal";
import { enqueueSnackbar } from "notistack";
import Inactive from "./UserInactive";
import Select from "react-select";
import StatusChangeModal from "./UserStatusChange";

const newStatusOptions = [
  { value: "isActive", label: "Active", variant: "success" },
  { value: "deactivate", label: "Deactivate", variant: "danger" },
  { value: "isFreeze", label: "Frozen", variant: "danger" },
  { value: "unfreeze", label: "Unfreeze", variant: "success" },
  { value: "isSuspended", label: "Suspended", variant: "danger" },
  { value: "unsuspend", label: "Unsuspend", variant: "success" },
  { value: "isDeleteAccount", label: "Deleted", variant: "danger" },
  { value: "restore", label: "Restore", variant: "success" },
];

// Function to get the current status label
const getStatusLabel = (row) => {
  if (row.isDeleteAccount) return "Deleted";
  if (row.isSuspended) return "Suspended";
  if (row.isFreeze) return "Frozen";
  if (row.isActive) return "Active";
  return "Unknown";
};

// Function to filter the options based on the current status
const getFilteredStatusOptions = (row) => {
  const currentStatus = getStatusLabel(row);

  switch (currentStatus) {
    case "Active":
      return newStatusOptions.filter((option) =>
        ["deactivate", "isFreeze", "isSuspended", "isDeleteAccount"].includes(
          option.value
        )
      );
    case "Frozen":
      return newStatusOptions.filter((option) =>
        ["unfreeze", "isDeleteAccount"].includes(option.value)
      );
    case "Suspended":
      return newStatusOptions.filter((option) =>
        ["unsuspend", "isDeleteAccount"].includes(option.value)
      );
    case "Deleted":
      return newStatusOptions.filter((option) =>
        ["restore"].includes(option.value)
      );
    default:
      return [];
  }
};

const options = [
  { value: "PENDING", label: "Pending", color: "#F1911D", icon: "fa fa-clock" },
  {
    value: "APPROVED",
    label: "Approved",
    color: "#15A552",
    icon: "fa fa-check",
  },
  {
    value: "REJECTED",
    label: "Rejected",
    color: "#fd6074",
    icon: "fa fa-close",
  },
];
const filteredOptions = options.filter((option) => option.value !== "PENDING");
const Option = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    className="text-white d-flex align-items-center justify-content-center m-1"
    style={{ backgroundColor: data.color, borderRadius: "3px", height: 28 }}
  >
    <i className={data.icon} style={{ marginRight: "5px" }}>
      {" "}
    </i>{" "}
    {label}
  </div>
);

const Users = () => {
  const navigate = useNavigate();
  const [totalRows, setTotalRows] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUsersData] = useState("");
  const [oneUserDetails, setOneUserDetails] = useState("");
  const [show, setshow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [failModal, setFailModal] = useState(false);
  const [isOpenDocs, setIsOpenDocs] = useState(false);
  const [modalContentUrl, setModalContentUrl] = useState("");
  const [status, setStatus] = useState("");
  const [docsKycId, setDocsKycId] = useState("");
  const [userId, setUserId] = useState("");
  const [docsName, setDocsName] = useState("");
  const [failureMsg, setFailureMsg] = useState({});
  const [selectedKycDocs, setSelectedKycDocs] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedStatusOption, setSelectedStatusOption] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFreeze, setIsFreeze] = useState(false);
  const [freezeChange, setFreezeChange] = useState(false);
  const [isFreezeId, setFreezeId] = useState("");
  const [isFreezeEmail, setFreezeEmail] = useState("");
  const [inActive, setInActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const [currentUser, setCurrentUser] = useState("");

  const handleUserStatusChange = (selectedOption, row) => {
    setCurrentUser(row);
    setUserStatus(selectedOption);
    setShowModal(true);
  };

  const handleConfirmChange = async () => {
    console.log(userStatus?.value, currentUser?.id);
    if (userStatus?.value === "unsuspend") {
      const response = await UserService.unsuspendUser(currentUser?.id);
      if (response) {
        AllUsers();
        setShowModal(false);
      }
    } else if (userStatus?.value === "isSuspended") {
      const response = await UserService.suspendUser(currentUser?.id);
      if (response) {
        AllUsers();
        setShowModal(false);
      }
    } else if (userStatus?.value === "isFreeze") {
      const response = await UserService.freezeUser(currentUser?.id, "true");
      if (response) {
        AllUsers();
        setShowModal(false);
      }
    } else if (userStatus?.value === "unfreeze") {
      const response = await UserService.freezeUser(currentUser?.id, "false");
      if (response) {
        AllUsers();
        setShowModal(false);
      }
    } else if (userStatus?.value === "deactivate") {
      const response = await UserService.reactivateUser(currentUser?.id);
      if (response) {
        AllUsers();
        setShowModal(false);
      }
    }
    // Implement the logic to update the status in your application
    setShowModal(false);
  };

  const handleInactive = () => {
    setInActive(true);
  };

  const handelDialogOpen = () => {
    setshow(true);
  };
  const AllUsers = async () => {
    try {
      setIsLoading(true);
      const response = await UserService.GetAllUsers({
        status: selectedStatus,
        pageNumber: pageNumber,
        pageSize: dataPerPage,
        searchText: searchValue,
      });
      const result = response.data.data;
      setTotalRows(response?.data?.totalUser);
      setUsersData(result);
      setIsLoading(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleStatus = async (selectedOption, kycId, name) => {
    setStatus(selectedOption?.value);
    setDocsKycId(kycId);
    setSelectedOption(selectedOption);
    const updatedKycDocs = oneUserDetails.kycDocs.map((item) => {
      if (item.id === kycId) {
        return {
          ...item,
          status: selectedOption.value,
          failureMsg:
            selectedOption.value === "REJECTED" ? failureMsg[kycId] || "" : "", // Set failureMsg only for rejected documents
        };
      }
      return item;
    });
    setOneUserDetails((prevState) => ({
      ...prevState,
      kycDocs: updatedKycDocs,
    }));
    if (selectedOption && selectedOption.value === "REJECTED") {
      setFailModal(true);
      setDocsName(name);
      // Set initial failureMsg value if not already set for the current kycId
      if (!failureMsg[kycId]) {
        setFailureMsg({
          ...failureMsg,
          [kycId]:
            oneUserDetails.kycDocs.find((item) => item.id === kycId)
              ?.failureMsg || "",
        });
      }
    }
  };
  const handleFormSubmit = async () => {
    try {
      const isAnyFailureMsgEmpty = oneUserDetails.kycDocs.some(
        (item) => item.status === "REJECTED" && !failureMsg[item.id]
      );

      if (isAnyFailureMsgEmpty) {
        // Display an error message or prevent form submission
        setIsError(true);
        console.error("Enter the failure message for all rejected documents.");
        return;
      }
      const response = await UserService.KycDocsStatus({
        userId: userId,
        kycDocs: oneUserDetails.kycDocs.map((item) => ({
          kycId: item.id,
          status: item.status,
          failureMessage:
            item.status === "REJECTED" ? failureMsg[item.id] || "" : "",
        })),
      });
      // setIsError(true)
      handleDetailsModal(userId);
    } catch (e) {
      console.log("e", e);
    }
    setshow(false);
    setFailModal(false);
    AllUsers();
  };
  const handleDocsModal = (item) => {
    setSelectedKycDocs(item);
    setIsOpenDocs(true);
    const fileUrl = item.document;
    setModalContentUrl(fileUrl);
  };
  const handleDetailsModal = async (id) => {
    setUserId(id);
    handelDialogOpen();
    if (id) {
      const response = await UserService.GetOneUserDetails(id);
      const result = response.data.data;
      setOneUserDetails(result);
      const updateMsg = {};
      result?.kycDocs?.forEach((item) => {
        if (item.status === "REJECTED") {
          updateMsg[item.id] = item.failureMessage || "";
          setFailureMsg(updateMsg);
        }
      });
      setSelectedOption(options[0]);
    }
  };
  useEffect(() => {
    AllUsers(selectedStatus, pageNumber, dataPerPage, searchValue);
  }, [pageNumber, dataPerPage, searchValue]);

  const DEBOUNCE_THRESHOLD = 500;
  const timeoutHandler = useRef(null);
  const debounceSearch = (event) => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    // setIsLoading(true)
    timeoutHandler.current = setTimeout(() => {
      // setSearchText(event);
      setSearchValue(event);
      // setIsLoading(false)
    }, DEBOUNCE_THRESHOLD);
  };
  const statusOptions = [
    { value: "", label: "All" },
    { value: "PENDING", label: "Pending" },
    { value: "completed", label: "Approved" },
    { value: "reject", label: "Rejected" },
  ];
  const handleStatusChange = (StatusOption) => {
    // setStatusPage(true);
    setSelectedStatusOption(StatusOption);
    setSelectedStatus(StatusOption ? StatusOption.value : "");
  };
  const handleGoButtonClick = () => {
    AllUsers();
    setPageNumber(1);
  };
  const handleResetButton = useCallback(() => {
    setSelectedStatusOption("");
    // setSearchText('');
    setSelectedStatus("");
    AllUsers();
  }, []);

  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    await AllUsers();
    setIsLoading(false);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await AllUsers();
    setIsLoading(false);
  };
  const handleFreezeModal = (row) => {
    setFreezeId(row.id);
    setFreezeEmail(row.firstName);
    setIsFreeze(true);
    setFreezeChange(row.isFreeze);
  };
  const freezzeUser = async () => {
    try {
      const response = await UserService.freezeUser(isFreezeId, !freezeChange);
      setIsFreeze(false);
      AllUsers();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const columns = [
    {
      name: <b>FIRST NAME</b>,
      cell: (row) => (row.firstName ? row.firstName : "-"),
      sortable: true,
    },
    {
      name: <b>LAST NAME</b>,
      cell: (row) => (row.lastName ? row.lastName : "-"),
      sortable: true,
    },
    {
      name: <b>EMAIL</b>,
      // selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
      cell: (row) => <div>{row.email ? row.email : "--"}</div>,
      width: "210px",
    },
    {
      name: <b>COUNTRY</b>,
      cell: (row) => (row.countryName ? row.countryName : "-"),
      sortable: true,
    },
    {
      name: <b>PHONE NO.</b>,
      cell: (row) => (row.mobileNumber ? row.mobileNumber : "-"),
      sortable: true,
    },
    {
      name: <b>STATUS</b>,
      selector: (row) => (
        <button
          className={`btn btn-sm action-btn btn-${
            row.kycStatus === "completed"
              ? "success"
              : row.kycStatus === "under verification"
              ? "warning"
              : "danger"
          } text-white`}
          style={{
            width: "135px",
            textAlign: "center",
            cursor: "default",
          }}
        >
          <i
            style={{ paddingRight: "3px" }}
            class={`fa fa-${
              row.kycStatus === "completed"
                ? "check"
                : row.kycStatus === "under verification"
                ? "clock"
                : "close"
            }`}
          ></i>{" "}
          {row.kycStatus === "completed"
            ? "Approved"
            : row.kycStatus === "under verification"
            ? "Under Varification"
            : "Rejected"}
        </button>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <div className="d-flex">
          <div
            onClick={() => navigate(`/users/details/${row.id}`, { state: row })}
            // onClick={() => handleDetailsModal(row.id)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-eye"></i>
          </div>
          {/* <Link
                        // to={`/ users / details / ${ row.id }`}
                        onClick={() => handleFreezeModal(row)}
                        className={`btn ${ row.isFreeze === true ? 'btn-danger' : 'btn-primary' } btn - sm`}
                    >
                        <i className="mdi mdi-account-off"></i>
                    </Link> */}
        </div>
      ),
      width: "100px",
    },
    /*  {
      name: <b>FREEZE USER</b>,
      selector: (row) => (
        <div className="d-flex">
          <div
            onClick={() => handleFreezeModal(row)}
            // onClick={() => handleDetailsModal(row.id)}
            className={`btn ${
              row.isFreeze === true ? "btn-danger" : "btn-primary"
            } btn-sm`}
          >
            <i className="fas fa-user-slash" style={{ width: "13px" }}></i>
          </div>
        </div>
      ),
      sortable: true,
      width: "140px",
    }, */
    {
      name: <b>USER STATUS</b>,
      cell: (row) => {
        const currentStatusLabel = getStatusLabel(row);
        const filteredOptions = getFilteredStatusOptions(row);
        const defaultValue = newStatusOptions.find(
          (option) => option.label === currentStatusLabel
        );

        return (
          <Select
            options={filteredOptions}
            value={defaultValue} // Show the current status as the default value
            onChange={(selectedOption) =>
              handleUserStatusChange(selectedOption, row)
            }
            isSearchable={false}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "150px",
              }),
              option: (provided, state) => ({
                ...provided,
                cursor: state.isDisabled ? "not-allowed" : "pointer",
              }),
            }}
            isOptionDisabled={(option) => option.value === defaultValue?.value} // Disable the current status option
          />
        );
      },
      sortable: false,
      width: "180px",
    },
  ];
  return (
    <>
      {show && (
        <UserDetails
          show={show}
          onHide={() => setshow(false)}
          oneUserDetails={oneUserDetails}
          handleStatus={handleStatus}
          selectedOption={selectedOption}
          filteredOptions={filteredOptions}
          Option={Option}
          failModal={failModal}
          docsName={docsName}
          setFailModal={setFailModal}
          isOpenDocs={isOpenDocs}
          setIsOpenDocs={setIsOpenDocs}
          modalContentUrl={modalContentUrl}
          handleDocsModal={handleDocsModal}
          handleFormSubmit={handleFormSubmit}
          setFailureMsg={setFailureMsg}
          status={status}
          failureMsg={failureMsg}
          docsKycId={docsKycId}
          selectedKycDocs={selectedKycDocs}
          isError={isError}
        />
      )}
      {isFreeze && (
        <FreezeModal
          show={isFreeze}
          onHide={() => setIsFreeze(false)}
          freezeId={isFreezeId}
          freezeEmail={isFreezeEmail}
          freezzeUser={freezzeUser}
          freezeChange={freezeChange}
        />
      )}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">USERS</h2>
        </div>
        <div className="d-flex align-items-center">
          {/* {show && <AddUser show={show} handleDialogClose={handleDialogCloseReset} handleSelectRoleChange={handleSelectRoleChange} BrandsOptions={BrandsOptions} userRole={userRole} formik={formik} id={id} />}
                        {<DeleteUserModal deleteModal={deleteModal} onHide={handleDeleteNodal} deleteId={deleteId} setDeleteModal={setDeleteModal} emailUse={emailUse} />} */}
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "wrap",
                        gap: "10px",
                        marginBottom: "14px",
                        marginTop: "4px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          debounceSearch(e.target.value);
                        }}
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "0.375rem 0.75rem",
                        }}
                      />
                      <div>
                        <SelectAll
                          value={selectedStatusOption}
                          onChange={handleStatusChange}
                          options={statusOptions}
                          placeholder="Status"
                        />
                      </div>
                      <div>
                        <Button
                          variant="success"
                          type="button"
                          onClick={handleGoButtonClick}
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          GO
                        </Button>
                      </div>
                      <div>
                        <Button
                          // variant="dark"
                          type="button"
                          onClick={() => handleResetButton()}
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          RESET
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Button
                        // variant="dark"
                        type="button"
                        onClick={handleInactive}
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        ADD INACTIVE
                      </Button>
                    </div>
                  </div>
                  <DataTable
                    data={userData}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <img src={Loader1} alt="Loader" height={70} />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
      {inActive && (
        <Inactive show={inActive} onClose={() => setInActive(false)} />
      )}
      {userStatus && (
        <StatusChangeModal
          show={showModal}
          onHide={() => setShowModal(false)}
          statusChange={userStatus}
          currentUser={currentUser}
          onConfirm={handleConfirmChange}
        />
      )}
    </>
  );
};
export default withLoader(Users);
