import { Typography } from 'antd'
import React, { useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import SelectAll from 'react-select'
import UserService from '../../../services/user.service'
import { useEffect } from 'react'
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import moment from 'moment/moment'
import sell from '../../../../assets/img/svgs/sell.svg'
import buy from '../../../../assets/img/svgs/buy.svg'
const walletOptions = [
    { value: "all", label: "All" },
    { value: "deposite", label: "Deposit" },
    { value: "withdrawal", label: "Withdrawal" },
];

const Wallet = (props) => {
    const [selectedWalletOption, setSelectedWalletOption] = useState(walletOptions[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState();
    const [walletBalance, setWalletBalance] = useState('');
    const [walletData, setWalletData] = useState([]);

    const handleWalletChange = (StatusOption) => {
        setSelectedWalletOption(StatusOption);
    };
    const GetWalletData = async () => {
        const response = await UserService.GetWalletDetails(props.id, selectedWalletOption.value, pageNumber, dataPerPage);
        const result = response.data.data
        setTotalRows(result?.totalTransaction)
        setWalletBalance(result?.walletBalance)
        setWalletData(result?.allTransaction)
    }
    const handlePageChange = async (newPageNumber) => {
        setIsLoading(true);
        setPageNumber(newPageNumber);
        await GetWalletData();
        setIsLoading(false)
    };
    const handlePerPageChange = async (newPerPage, newPageNumber) => {
        setIsLoading(true)
        setDataPerPage(newPerPage);
        setPageNumber(newPageNumber);
        await GetWalletData();
        setIsLoading(false)
    };
    useEffect(() => {
        GetWalletData()
    }, [props.id, pageNumber, selectedWalletOption])

    const columns = [
        {
            name: <b>TRANSACTION ID</b>,
            selector: (row) => (row.id ? row.id : "-"),
            sortable: true,
            width: '280px'
        },
        {
            name: <b>INCOME SOURCE</b>,
            selector: (row) => (row.transactionType ? row.transactionType : "-"),
            sortable: true,
        },
        {
            name: <b>TYPE</b>,
            selector: (row) => (row.txnType ? (row.txnType === 'CR' ? 'Deposit' : 'Withdrawal') : "-"),
            sortable: true,
        },
        {
            name: <b>AMOUNT</b>,
            selector: (row) => (row.amount ? row.amount : "-"),
            sortable: true,
        },
        {
            name: <b>DATE</b>,
            selector: (row) => <div>{(moment(row.createdat).format("DD-MM-YYYY")) + " " + (moment(row.createdat).format("hh:mm A"))}</div>,
            sortable: true,
        },
        {
            name: <b>STATUS</b>,
            selector: (row) => <div>{row.transactionStatus === "success" ? (
                <img src={buy} alt='image' width={30} />
            ) : (
                <img src={sell} alt='image' />
            )}</div>,
            sortable: true,
        },
    ];
    return (
        <>
            <div lg={12} className="w-100">
                <Card className="custom-card overflow-hidden mt-3">
                    <Card.Body>
                        <div className="responsive">
                            <Card className="custom-card overflow-hidden">
                                <div>
                                    <div className='wallet-res'>
                                        <Card className="card-ress">
                                            <Card.Body className='h-100 wallet-balance'>
                                                <Row>
                                                    <div className='cardText-res'>
                                                        <label className='tx-res fw-bold text-light mb-0'>Wallet balance</label>
                                                        <Typography className='fw-bold tx-ress bal-res'>
                                                            <span>{walletBalance}</span>
                                                            <span className='tx-16'>KD</span>
                                                        </Typography>
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <SelectAll
                                            value={selectedWalletOption}
                                            onChange={handleWalletChange}
                                            options={walletOptions}
                                            defaultValue={walletOptions[0]}
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: 135,

                                                }),
                                            }}
                                        />
                                    </div>
                                    <DataTable
                                        data={walletData}
                                        columns={columns}
                                        noHeader
                                        persistTableHead
                                        center={true}
                                        highlightOnHover
                                        striped
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={dataPerPage}
                                        onChangeRowsPerPage={(page, totalRows) => {
                                            handlePerPageChange(page, 1);
                                        }}
                                        onChangePage={handlePageChange}
                                        progressPending={isLoading}
                                        progressComponent={
                                            <img src={Loader1} alt="Loader" height={70} />
                                        }
                                    />
                                </div>
                            </Card>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default Wallet
