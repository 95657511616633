import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import PropertiesService from "../../../services/properties.service";
import user from "../../../../assets/img/svgs/userDefault.svg";
import { useEffect } from "react";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import TransfershareModal from "./TransfershareModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { BiTransfer } from "react-icons/bi";
const ShareDetails = () => {
  const [totalRows, setTotalRows] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [shareLoader, setShareLoader] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [shareErr, setShareErr] = useState(false);
  const [isTranfer, setISTransfer] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [shareData, setShareData] = useState([]);
  const [transferData, setTransferData] = useState("");
  const { shareNumber } = useParams();
  const locationShare = shareNumber;
  const [currentShare, setCurrentShare] = useState("");
  const getStore = localStorage.getItem("currentShare");
  if (getStore === null) {
    localStorage.setItem("currentShare", locationShare);
  }
  const [allShare, setAllShare] = useState([]);
  const [allowedLength, setAllowedLength] = useState(0);
  const [shareColor, setShareColor] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const propertyId = location?.state?.propertyId;
  const allShareData = location?.state?.data;
  const typeProperty = location?.state?.propertyType;
  const inputShareRef = useRef();
  // const storeShare = localStorage.setItem("currentShare", currentShare)

  const initialValue = {
    from: "",
    propId: "",
    shareNo: "",
    to: "",
    withFees: false,
  };
  const validationSchema = Yup.object().shape({
    to: Yup.string().required("Please enter the email"),
  });

  const handleCheck = (e) => {
    formik.setFieldValue("withFees", e.target.checked);
  };
  const handleDialogClose = () => {
    setISTransfer(false);
    formik.resetForm();
  };
  const handlePageChange = async (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
  };
  const getShareDetails = async () => {
    // setIsLoading(true)
    const response = await PropertiesService.shareDetails(
      getStore ? getStore : locationShare,
      pageNumber,
      dataPerPage
    );
    const result = response?.data?.data;
    setShareData(result);
    setTotalRows(response?.data?.totalShares);
    setIsLoading(false);
  };
  const handleFormSubmit = async (values, action) => {
    const { to, withFees, propId, shareNo } = values;
    try {
      const response = await PropertiesService.changeOwner({
        email: to,
        isFree: withFees,
        propertyId: propId,
        shareNumber: shareNo,
      });
      getShareDetails();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    action.resetForm();
    setISTransfer(false);
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleNavigateBack = () => {
    navigate(`/properties/details/${propertyId}`, { state: typeProperty });
    localStorage.removeItem("currentShare");
  };
  const handleDecrement = () => {
    const index = allShare?.map((item) => item.shareNumber);
    const currentIndex = index.indexOf(getStore);
    if (currentIndex > 0) {
      const share = allShare[currentIndex - 1];
      setCurrentShare(share?.shareNumber);
      localStorage.setItem("currentShare", share?.shareNumber);
      setShareColor(share?.status);
    }
  };
  const handleIncrement = () => {
    const index = allShare?.map((item) => item.shareNumber);
    const currentIndex = index.indexOf(getStore);
    if (currentIndex < allShare.length - 1) {
      const share = allShare[currentIndex + 1];
      setCurrentShare(share?.shareNumber);
      localStorage.setItem("currentShare", share?.shareNumber);
      setShareColor(share?.status);
    }
  };
  const handleInputChange = (e) => {
    const newShareNumber = e.target.value;
    const numericValue = newShareNumber.replace(/[^0-9.]/g, "");
    setCurrentShare(numericValue);
    localStorage.setItem("currentShare", numericValue);
    if (numericValue === "") {
      setShareErr(true);
    } else {
      setShareErr(false);
    }
  };
  const handleUpdateShare = (e) => {
    setShareOpen(true);
    if (!shareOpen) {
      setTimeout(() => {
        inputShareRef.current.focus();
      }, 0);
    } else {
      setShareLoader(true);
      const shareList = allShare.map((index) => index.shareNumber);
      if (!shareList.includes(currentShare)) {
        // setShareOpen(false)
        setShareErr(true);
        enqueueSnackbar("This share number is not exist", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        getShareDetails();
        setShareOpen(false);
      }
      if (currentShare === "") {
        setShareErr(true);
        return;
      }
      setShareLoader(false);
    }
  };
  const handleTransfershare = () => {
    formik.setFieldValue("from", shareData[0]?.currentowneremail);
    formik.setFieldValue("propId", shareData[0]?.propertyid);
    formik.setFieldValue("shareNo", shareData[0]?.sharenumber);
    setTransferData(shareData[0]);
    setISTransfer(true);
  };
  useEffect(() => {
    getShareDetails();
  }, [getStore, locationShare, pageNumber, dataPerPage]);

  useEffect(() => {
    const number = allShareData?.map((item) => item);
    setAllShare(number);
  }, []);

  useEffect(() => {
    const index = allShare?.map((item) => item.shareNumber);
    const currentIndex = index?.indexOf(getStore ? getStore : locationShare);
    const share = allShare[currentIndex];
    setShareColor(share?.status);
    if (index?.length > 0) {
      setAllowedLength(index[0].length);
    }
  }, [allShare]);
  const columns = [
    {
      name: <b>CURRENT OWNER</b>,
      selector: (row) => (
        <>
          <div className="demo-avatar-group my-auto float-end gap-2">
            <div className="main-img-user avatar-sm">
              <img
                alt="avatar"
                className="rounded-circle"
                src={row?.currentownerprofile ? row?.currentownerprofile : user}
              />
            </div>
            {row.currentownerusername ? row.currentownerusername : "-"}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: <b>PREVIOUS OWNER</b>,
      selector: (row) => (
        <>
          <div className="demo-avatar-group my-auto float-end gap-2">
            <div className="main-img-user avatar-sm">
              <img
                alt="avatar"
                className="rounded-circle"
                src={
                  row?.previousownerprofile ? row?.previousownerprofile : user
                }
              />
            </div>
            {row.previousownerusername ? row.previousownerusername : "-"}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: <b>PRICE PER SHARE</b>,
      selector: (row) => (row.pricepershare ? row.pricepershare : "-"),
      sortable: true,
    },
    {
      name: <b>DATE</b>,
      selector: (row) =>
        moment(row.transactiondate).format("DD-MM-YYYY") +
        " " +
        moment(row.transactiondate).format("hh:mm A"),
      sortable: true,
    },
    // {
    //   name: <b>ACTION</b>,
    //   selector: (row) => (
    //     <div className="d-flex">
    //       <div
    //         onClick={() => handleTransfershare(row)}
    //         className="btn btn-primary btn-sm"
    //       >
    //         {/* <TbTransfer /> */}
    //         <i className="fa-regular fa-arrow-right-arrow-left"></i>
    //       </div>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
  ];

  return (
    <>
      {isTranfer && (
        <TransfershareModal
          formik={formik}
          transferData={transferData}
          handleCheck={handleCheck}
          show={isTranfer}
          onHide={handleDialogClose}
        />
      )}
      <div className="page-header">
        <div className="d-flex align-items-center justify-content-start gap-2">
          <Button
            style={{ borderRadius: "50%", marginRight: "8px" }}
            onClick={() => handleNavigateBack()}
          >
            <i className="d-block fe fe-arrow-left"></i>
          </Button>
          <h2 className="main-content-title tx-24 mg-b-0">
            {/* Details: {shareNumber} */}
            <InputGroup style={{ maxWidth: "200px" }}>
              <Button
                onClick={handleDecrement}
                className="bg-light border border-light"
              >
                -
              </Button>
              <FormControl
                style={{
                  background:
                    shareColor === "company"
                      ? "#023220"
                      : shareColor === "open_order"
                      ? "#46C646"
                      : "#C30000",
                  border: "1px solid #98bdaf47",
                  color: "white",
                }}
                type="text"
                value={getStore ? getStore : locationShare}
                autoComplete="off"
                autofill="false"
                disabled={!shareOpen ? true : false}
                onChange={(e) => handleInputChange(e)}
                ref={inputShareRef}
                maxLength={allowedLength}
                // readOnly
              />
              {/* {shareErr &&
                                <p className="text-start error">
                                    {"Enter the share number!"}
                                </p>
                            } */}
              <Button
                onClick={handleIncrement}
                className="bg-light borer border-light"
              >
                +
              </Button>
            </InputGroup>
          </h2>
          <Button
            style={{ borderRadius: "50%", marginRight: "8px" }}
            type="button"
            onClick={() => handleUpdateShare()}
            disabled={shareErr}
          >
            {shareLoader ? (
              <Spinner />
            ) : !shareOpen ? (
              <i className="d-block fe fe-edit"></i>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden p-1">
                <div>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      type="button"
                      className="mb-3 btn-icon-text"
                      onClick={() => handleTransfershare()}
                    >
                      <BiTransfer />
                    </Button>
                  </div>
                  <DataTable
                    data={shareData}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <img src={Loader1} alt="Loader" height={70} />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ShareDetails;
