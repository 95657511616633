import serverCall from "../../serverCall";
const GetAllUsers = async ({
  status: selectedStatusOption,
  pageNumber: pageNumber,
  pageSize: dataPerPage,
  searchText: searchText,
}) => {
  try {
    const response = await serverCall.get(
      `/users/admin/get-all-user?status=${
        selectedStatusOption === undefined ? "" : selectedStatusOption
      }&pageNumber=${pageNumber === undefined ? "" : pageNumber}&pageSize=${
        dataPerPage === undefined ? "" : dataPerPage
      }&searchText=${searchText === undefined ? "" : searchText}`
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const KycDocsStatus = async (data) => {
  try {
    const response = await serverCall.post(
      "/users/admin/KYC-verification",
      data
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const GetOneUserDetails = async (id) => {
  try {
    const response = await serverCall.post(
      `/users/admin/get-userdetail?userId=${id}`
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const GetPortfolioDetails = async (id, page, limit) => {
  const response = await serverCall.get(
    `/property/admin/portfolio?userId=${id}&pageNumber=${page}&pageSize=${limit}`
  );
  return response;
};
const GetTradesDetails = async (id, page, limit, type) => {
  const response = await serverCall.get(
    `/open-order/admin/get-trade?userId=${id}&pageNumber=${page}&pageSize=${limit}&tradeType=${type}`
  );
  return response;
};
const GetWalletDetails = async (id, type, page, limit) => {
  const response = await serverCall.get(
    `wallet/admin/get-wallet-detail?userId=${id}&type=${type}&pageNumber=${page}&pageSize=${limit}`
  );
  return response;
};
const freezeUser = async (userId, freeze) => {
  const response = await serverCall.post(
    `users/admin/freeze-user?userId=${userId}&isFreeze=${freeze}`
  );
  return response;
};

const suspendUser = async (userId) => {
  const response = await serverCall.post(
    `users/admin/suspend-user?userId=${userId}`
  );
  return response;
};

const unsuspendUser = async (userId) => {
  const response = await serverCall.post(
    `users/admin/unsuspend-user?userId=${userId}`
  );
  return response;
};

const reactivateUser = async (userId) => {
  const response = await serverCall.post(
    `users/admin/reactivate?userId=${userId}`
  );
  return response;
};

const inActiveUserNotification = async (payload) => {
  const response = await serverCall.post(
    "notification/setting/inactive-user",
    payload
  );
  return response;
};

const UserService = {
  GetAllUsers,
  KycDocsStatus,
  GetOneUserDetails,
  GetPortfolioDetails,
  GetTradesDetails,
  GetWalletDetails,
  freezeUser,
  suspendUser,
  unsuspendUser,
  reactivateUser,
  inActiveUserNotification,
};
export default UserService;
