import { Typography } from 'antd'
import React, { useState } from 'react'
import { Badge, Card, Col, Pagination, Row } from 'react-bootstrap'
import SelectAll from 'react-select'
import buy from '../../../../assets/img/svgs/buy.svg';
import sell from '../../../../assets/img/svgs/sell.svg';
import UserService from '../../../services/user.service';
import { useEffect } from 'react';
import ModelSS from '../support/ModelSS';

const tradOptions = [
    { value: "Open", label: "Open" },
    { value: "Executed", label: "Excuted" },
];
const Trades = (props) => {
    const [selectedTradOption, setSelectedTradOption] = useState(tradOptions[0]);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState();
    const [tradOpen, setTradOpen] = useState([]);
    const [screenshot, setScreenshots] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const limit = 6

    const GetTradesDetails = async () => {
        const response = await UserService.GetTradesDetails(props.id, page, limit, selectedTradOption.value)
        const result = response?.data?.data
        setTradOpen(result)
        setTotalData(response?.data?.totalOrder)
    }
    useEffect(() => {
        GetTradesDetails()
    }, [props.id, selectedTradOption, page])
    const nextPage = (page) => {
        setPage(page);
    };
    const previousPage = (page) => {
        setPage(page);
    };
    const handleTradChange = (StatusOption) => {
        setSelectedTradOption(StatusOption);
        setPage(1)

    };
    const handleTradImgModal = (open) => {
        setIsOpen(true);
        const images = open?.propertyimages || [];
        setScreenshots(images);
    }
    return (
        <>
            {isOpen && <ModelSS show={isOpen} onHide={() => setIsOpen(false)} screenshot={screenshot} />}
            <div style={{ marginLeft: 'auto', width: "max-content", marginBottom: '10px' }}>
                <SelectAll
                    value={selectedTradOption}
                    onChange={handleTradChange}
                    options={tradOptions}
                    defaultValue={tradOptions[0]}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            width: 115,

                        }),
                    }}
                />
            </div>
            <Row>
                {tradOpen.map((open, index) => {
                    const address = open?.propertyname + " " + open?.propertyaddressarea + " " + open?.propertyaddresscountryname
                    const lines = open?.propertyaddressline1 + " " + open?.propertyaddressline2
                    return (
                        <Col md={6} xs={12} lg={4}>
                            <Card className='custom-card productdesc' key={index}>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={4} className='padding-zero'>
                                            <div onClick={() => handleTradImgModal(open)}>
                                                <img src={open?.propertyimages[0]} alt='image' style={{ borderRadius: "8px", cursor: "pointer" }} />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <label className='tx-18 fw-bold mt-2 mb-0 property-text' title={address}>{address}</label>
                                            <Typography className='text-muted property-text' title={lines}>{lines}</Typography>
                                            <div>
                                                {open?.ordertype === "Buy" ? (
                                                    <Badge className='trad-badge'
                                                        bg='none'
                                                        pill
                                                        style={{
                                                            backgroundColor: '#0596691A',
                                                            color: '#059669'
                                                        }}
                                                    >
                                                        <img src={buy} alt='image' width={18} style={{ marginRight: '3px' }} />
                                                        {open.ordertype}
                                                    </Badge>
                                                ) : (
                                                    <Badge className='trad-badge'
                                                        bg='none'
                                                        pill
                                                        style={{
                                                            backgroundColor: '#DE47471A',
                                                            color: '#DE4747'
                                                        }}
                                                    >
                                                        <img src={sell} alt='image' width={18} style={{ marginRight: '3px' }} />
                                                        {open.ordertype}
                                                    </Badge>
                                                )}

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-between'>
                                            <Typography className='tx-18 user-text'>Order Type</Typography>
                                            <Typography className='fw-bold tx-20'>{open.ordertype}</Typography>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-between'>
                                            <Typography className='tx-18 user-text'>Quantity</Typography>
                                            <Typography className='fw-bold tx-20'>{open.orderquantity}</Typography>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-between'>
                                            <Typography className='tx-18 user-text'>Purchase Price (KD/Share)</Typography>
                                            <Typography className='fw-bold tx-20'>{open.orderpriceshare}</Typography>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-between'>
                                            <Typography className='tx-18 user-text'>5% Platform Fee (KD)</Typography>
                                            <Typography className='fw-bold tx-20'>{open.orderplatformfee}</Typography>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-between'>
                                            <Typography className='tx-18 user-text property-text' title='Income per share (KD)'>Income per share (KD)</Typography>
                                            <Typography className='fw-bold tx-20 property-text' title={open.rentalIncomePerShare}>{open.rentalIncomePerShare}</Typography>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-between'>
                                            <Typography className='tx-18 user-text'>Purchased Amount</Typography>
                                            <Typography className='fw-bold tx-20'>{open.ordertotalprice}</Typography>
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
                {totalData > limit && (
                    <Pagination
                        className="pagination-circle mb-0 ms-lg-3"
                        style={{ justifyContent: "center" }}
                    >
                        <Pagination.Item
                            onClick={() => previousPage(page - 1)}
                            disabled={page === 1}
                        >
                            <i className="fas fa-angle-double-left"></i>
                        </Pagination.Item>
                        {Array.from({ length: Math.ceil(totalData / limit) }).map(
                            (_, index) => {
                                const pageNumber = index + 1;
                                return (
                                    <Pagination.Item
                                        onClick={() => nextPage(pageNumber)}
                                        key={pageNumber}
                                        active={pageNumber === page}
                                    >
                                        {pageNumber}
                                    </Pagination.Item>
                                );
                            }
                        )}
                        <Pagination.Item
                            onClick={() => nextPage(page + 1)}
                            disabled={page === Math.ceil(totalData / limit)}

                        >
                            <i className="fas fa-angle-double-right"></i>
                        </Pagination.Item>
                    </Pagination>
                )}
            </Row>
        </>
    )
}

export default Trades
